var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "loader-wrapper",
      attrs: {
        vertical: "",
        justify: "center",
        gap: "3rem",
        "no-content-stretch": "",
      },
    },
    [
      _c("cube-loader"),
      _c("div", [_vm._v(_vm._s(_vm.$t("Signing in to Steelspace...")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }